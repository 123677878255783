import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import TeamMemberCard from '../common/TeamMemberCard'
import team_allison from '../../assets/images/team_allison.jpg'
import team_callie from '../../assets/images/team_callie.jpg'
import team_amy from '../../assets/images/team_amy.jpg'
import team_ream from '../../assets/images/team_ream.jpg'

const TeamMembersSection = () => {
    return (
        <section className="team-members-section px-3 px-md-5 pt-3 pb-2 mb-0 mb-md-2">
            <Container className="pt-0 pt-md-2 pb-2 pb-md-5 px-0 px-md-2">
                <Row
                    sm={12}
                    className="justify-content-center text-center pt-0 pb-4"
                >
                    <Col sm={12}>
                        <h2 className="section-title pb-2 mb-3">
                            Meet Our Team
                        </h2>
                    </Col>
                </Row>
                <Row
                    sm={12}
                    className="card-wrapper py-2 my-3 justify-content-between"
                >
                    <Col
                        xs={12}
                        md={6}
                        lg={3}
                        className="pb-2 pb-md-3 mb-4 mb-md-5"
                    >
                        <TeamMemberCard
                            name="Allison Murrell"
                            role="President"
                            imgUrl={team_allison}
                            bio="Allison B. Murrell serves as the President of Reamer Made, LLC. Allison started working in the industry in 2014 alongside her father at their family’s third-generation heavy civil construction company in SWFL. Allison quickly realized that the construction industry was still very behind when it came to technology and marketing, so she decided to use her knowledge of construction and marketing to help other companies in this industry. Allison attended the University of South Carolina where she received her Bachelor of Science degree in International Business and Global Supply Chain and Operations Management with a minor in Spanish. She served as a Logistics Supervisor for PepsiCo for several years where she supervised a team of over 30 people and integrated new software into the warehouse and transportation systems. Outside of work, Allison enjoys traveling abroad and instructing a variety of classes at the local Pure Barre studio."
                        />
                    </Col>
                    <Col
                        xs={12}
                        md={6}
                        lg={3}
                        className="pb-2 pb-md-0 pt-0 pt-md-3 mt-0 mt-md-5 mb-4 mb-md-0"
                    >
                        <TeamMemberCard
                            name="Callie Costello"
                            role="Marketing Manager"
                            imgUrl={team_callie}
                            bio="Callie Costello is our Marketing Manager for Reamer Made Originally from Montana, Callie grew up along her family’s construction businesses. After graduating college with a bachelor’s degree in art history and German, she took a chance and moved to across the world to Munich, Germany. There, she spent almost 6 years working in the medical device industry before returning to the states 2 years ago. Her international experiences give Callie a unique perspective when it comes making clients' ideas come alive; whether they're corporate or creative based. Now at Reamer made, she is able to bring her corporate marketing background and construction roots to help our clients create the best outcomes and products possible. Aside from her career, Callie enjoys reading, freediving, traveling and exploring new cultures."
                        />
                    </Col>
                    <Col
                        xs={12}
                        md={6}
                        lg={3}
                        className="pb-4 pb-md-4 mb-4 mb-md-5"
                    >
                        <TeamMemberCard
                            name="Amy Sonnicksen"
                            role="Creative Director"
                            imgUrl={team_amy}
                            bio="Amy Sonnicksen is our Creative Director at Reamer Made. Born and raised in the Chicagoland area, Amy moved to Florida three years ago. She has an extensive background in photography and digital media, having begun her freelance career in 2015 during her undergrad at Indiana University. After working for a Naples based civil engineering and construction company, Quality Enterprises (QE),  Amy discovered her love for documenting the everyday life of workers in America’s toughest jobs - construction. During her time at QE, she grew to love the dirt world and wanted to be a part of it for the continued future. When not working Amy enjoys reading, sailing, and adventures with her dog, Crash. "
                        />
                    </Col>
                    <Col
                        xs={12}
                        md={6}
                        lg={3}
                        className="pb-2 pb-md-0 pt-0 pt-md-3 mt-0 mt-md-5 mb-4 mb-md-0"
                    >
                        <TeamMemberCard
                            name="Reamer"
                            role="Company Mascot"
                            imgUrl={team_ream}
                            bio="It’s a dog’s life at Reamer Made. And a pretty good one, at that. Loves to cuddle. Hates to be ignored. Always up for a treat. Introducing Reamer, our mascot."
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default TeamMembersSection
