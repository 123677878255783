import { Col, Row } from 'react-bootstrap'
import React from 'react'
import Featured_project_1 from '../../assets/images/featured_project_1.jpg'
import Featured_project_2 from '../../assets/images/featured_project_2.jpg'
import Featured_project_3 from '../../assets/images/featured_project_3.jpg'
import Featured_project_4 from '../../assets/images/featured_project_4.jpg'
import Featured_project_5 from '../../assets/images/featured_project_5.jpg'
import Featured_project_6 from '../../assets/images/featured_project_6.jpg'
import PopupImage from './PopupImage'

const PhotoGallery = () => {
    return (
        <Row className="photo-row flex-wrap pt-4 pb-2 pb-md-4 px-0 mx-0">
            <Col xs={4} className="px-0">
                <div className="window" id="photo-1">
                    <PopupImage
                        src={Featured_project_1}
                        href="https://lucarellicompanies.com/"
                        name="Lucarelli Homes"
                        service="Website"
                        overview="RM created the website for Lucarelli Homes, a family-owned and operated business that has been providing quality custom homebuilding services to Southwest Florida for over two decades."
                    />
                </div>
                <div className="window" id="photo-2">
                    <img
                        src={Featured_project_2}
                        alt=""
                    />
                </div>
            </Col>
            <Col xs={4} className="px-0">
                <div className="window" id="photo-3">
                    <img
                        src={Featured_project_3}
                        alt=""
                    />
                </div>
                <div className="window" id="photo-4">
                    <PopupImage
                        src={Featured_project_4}
                        href="https://www.lightherway.com/"
                        name="Light Her Way"
                        service="Logo Design & Branding"
                        overview="RM designed the logo for Light Her Way, a woman-owned consulting business focused on building sales and business strategies for companies with an emphasis on helping developing countries."
                    />
                </div>
            </Col>
            <Col xs={4} className="px-0">
                <div className="window" id="photo-5">
                    <img
                        src={Featured_project_5}
                        alt=""
                    />
                </div>
                <div className="window" id="photo-6">
                    <img
                        src={Featured_project_6}
                        alt=""
                    />
                </div>
            </Col>
        </Row>
    )
}

export default PhotoGallery
