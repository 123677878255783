import HeroSection1 from '../components/home/HeroSection1'
import WhatWeDoSection from '../components/home/WhatWeDoSection'
import ServicesSection from '../components/home/OurServicesSection'
import GallerySection from '../components/home/GallerySection'
import WhyUsSection from '../components/home/WhyUsSection'
import FeaturedProjectsSection from '../components/home/FeaturedProjectsSection'
import OurClientsSection from '../components/home/OurClientsSection'
import ContactUsSection from '../components/home/ContactUsSection'
import TestimonialsSection from '../components/home/TestimonialsSection'

const Home = () => {
    return (
        <>
            <HeroSection1 />
            <WhatWeDoSection />
            <GallerySection />
            <ServicesSection />
            <WhyUsSection />
            <div id="featured-projects">
                <FeaturedProjectsSection />
            </div>
            <OurClientsSection />
            <TestimonialsSection />
            <ContactUsSection />
        </>
    )
}

export default Home
