import CustomNavbar from './components/common/CustomNavbar'
import Footer from './components/common/Footer'
import { Outlet, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import metaConfig from "./metaConfig.json"

function getMetaTags(path) {
    const meta = Object.values(metaConfig).find(config => config.path === path);
    return {
        title: meta ? meta.title : 'Reamer Made',
        description: meta ? meta.description : 'We are dedicated to helping you tell your story and branding yourself in the industry.'
    }
  }

const Layout = (props) => {
    const location = useLocation()
    const routeName = location.pathname;
    const { title, description } = getMetaTags(routeName);


    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({ behavior: 'smooth' })
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
    }, [location])
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <CustomNavbar />
            <Outlet />
            <Footer />
        </>
    )
}

export default Layout
