import HeroSection3 from '../components/ourServices/HeroSection3'
import OurServicesSection from '../components/ourServices/OurServicesSection'
import OtherServicesSection from '../components/ourServices/OtherServicesSection'

const OurServices = () => {
    return (
        <>
            <HeroSection3 />
            <div id="our-services">
                <OurServicesSection />
            </div>

            <OtherServicesSection />
        </>
    )
}

export default OurServices
