import { Container } from 'react-bootstrap'
import React from 'react'
import BannerOverImage from '../common/BannerOverImage'
import Home_contact from '../../assets/images/home_contact.jpg'

const ContactUsSection = () => {
    return (
        <section className="contact-us-section bg-white pt-4 pt-md-5 pt-md-3 pb-4 mb-2 mb-md-3 px-4">
            <Container className="mb-2 mb-md-5 px-0 px-md-2">
                <BannerOverImage
                    bannerTitle="Interested in our services?"
                    bannerContent="At Reamer Made, we understand that construction can be a tough business - but it doesn't have to be completely a grind. We are dedicated to helping you tell your story and branding yourself in the industry."
                    buttonText="Contact Us"
                    buttonLink="/contact-us"
                    imageUrl={Home_contact}
                    alignBannerRight={false}
                    bannerCustomClasses="mobile-full-width mt-2 mt-md-0"
                    imageCustomClasses="d-none d-md-block"
                />
            </Container>
        </section>
    )
}

export default ContactUsSection
