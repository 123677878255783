import HeroSection4 from '../components/contactUs/HeroSection4'
import ContactUsSection from '../components/contactUs/ContactUsSection'
import GetInTouchSection from '../components/contactUs/GetInTouchSection'

const ContactUs = () => {
    return (
        <>
            <HeroSection4 />
            <ContactUsSection />
            <div id="get-in-touch">
                <GetInTouchSection />
            </div>
        </>
    )
}

export default ContactUs
