import HeroSection2 from '../components/aboutUs/HeroSection2'
import WhyChooseUsSection from '../components/aboutUs/WhyChooseUsSection'
import TeamMembersSection from '../components/aboutUs/TeamMembersSection'
import ScheduleAMeetingSection from '../components/aboutUs/ScheduleAMeetingSection'

const AboutUs = () => {
    return (
        <>
            <HeroSection2 />
            <WhyChooseUsSection />
            <div id="our-team">
                <TeamMembersSection />
            </div>
            <div id="schedule-meeting">
                <ScheduleAMeetingSection />
            </div>
        </>
    )
}

export default AboutUs
