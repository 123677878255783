import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ServiceCard from '../common/ServiceCard'
import service_strategy from '../../assets/images/service_strategy.jpg'
import service_media from '../../assets/images/service_media.jpg'
import service_design from '../../assets/images/service_design.jpg'
import service_data from '../../assets/images/service_data.jpg'
import service_digital from '../../assets/images/service_digital.jpg'

const OurServicesSection = () => {
    return (
        <section className="our-services-section pt-2 pt-md-5 px-4 px-md-0">
            <Container className="py-5 px-0 px-md-3">
                <Row
                    sm={12}
                    className="justify-content-center text-center pt-2 pb-2 px-0"
                >
                    <Col sm={12}>
                        <h2 className="section-title mb-2">Our Services</h2>
                    </Col>
                </Row>
                <Row
                    sm={12}
                    className="card-wrapper py-2 my-0 mt-md-5 mb-md-4 justify-content-center"
                >
                    <ServiceCard
                        cardTitle="Strategy"
                        cardText="The foundation for all that we do. Design services are tailored to help you break through the clutter and succeed at every level to meet your business goals and objectives."
                        cardImageUrl={service_strategy}
                        listItems={[
                            'Digital Strategy',
                            'Content Strategy',
                            'Media Planning',
                            'Consumer & Market Research',
                            'Public Relations Strategy',
                        ]}
                    />
                    <ServiceCard
                        cardTitle="Media"
                        cardText="A new and unique way to tell your story."
                        cardImageUrl={service_media}
                        listItems={['Photography', 'Video', 'Animation']}
                    />
                    <ServiceCard
                        cardTitle="Experience & Design"
                        cardText="Creative branding that engages your audience and drives results."
                        cardImageUrl={service_design}
                        listItems={[
                            'Website Design',
                            'Mobile App Design',
                            'Branding & Identity',
                            'User Experience',
                            'Content Creation',
                        ]}
                    />
                    <ServiceCard
                        cardTitle="Data, Analytics, Optimization"
                        cardText="Consumer-centric, data-driven insights that go beyond impressions, visits, and clicks."
                        cardImageUrl={service_data}
                        listItems={[
                            'Advanced Analytics Consulting',
                            'Conversion Rate Optimization',
                            'Site Speed & Performance Optimization',
                            'Marketing Automation',
                        ]}
                    />
                    <ServiceCard
                        cardTitle="Digital Marketing & Promotion"
                        cardText="It’s about standing out from your competition, quality engagement and creating future business leads."
                        cardImageUrl={service_digital}
                        listItems={[
                            'Social Media',
                            'SEO Optimization',
                            'Paid Media Marketing',
                            'Email Campaigns',
                            'Lead Generation Strategies',
                            'Book a Meeting',
                        ]}
                    />
                </Row>
            </Container>
        </section>
    )
}

export default OurServicesSection
