import { Col, Container, Row } from 'react-bootstrap'
import React from 'react'
import ContactDetailsField from '../common/ContactDetailsField'
import {
    BsFillEnvelopeFill,
    BsFillTelephoneFill,
    BsGeoAltFill,
} from 'react-icons/bs'

const ContactUsSection = () => {
    return (
        <section className="contact-us-section-2 pt-1 pt-md-2 pb-2 px-4">
            <Container className="py-md-5 px-0">
                <Row
                    sm={12}
                    className="justify-content-between text-center pt-4 pb-2 flex-column-reverse flex-md-row"
                >
                    <Col md={7} lg={8} className="map-wrapper">
                        <iframe
                            title="map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3579.7327434105487!2d-81.80182148499279!3d26.205370796489564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88db1e159a124589%3A0xc0fca90f26eedfd3!2s4850%20Tamiami%20Trail%20N%2C%20Naples%2C%20FL%2034103%2C%20USA!5e0!3m2!1sen!2s!4v1673475429546!5m2!1sen!2s"
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Col>
                    <Col
                        md={5}
                        lg={4}
                        className="px-0 pt-2 pt-md-3 pb-3 pb-md-2 ps-md-5"
                    >
                        <h3 className="section-title pt-3 pt-lg-0 pb-3 mb-4">
                            Contact Us
                        </h3>
                        <ContactDetailsField
                            type="phone"
                            icon={<BsFillTelephoneFill />}
                            line1="+1(239)300-2019"
                        />
                        <ContactDetailsField
                            icon={<BsGeoAltFill />}
                            line1="4850 Tamiami Trail N"
                            line2="Naples, FL 34103"
                        />
                        <ContactDetailsField
                            type="email"
                            icon={<BsFillEnvelopeFill />}
                            line1="contact@reamermade.com"
                            line2=""
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ContactUsSection
