import { Col, Container, Dropdown, Row } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import React, { useRef } from 'react'

const NavbarOptions = () => {
    const location = useLocation()
    const hash = location.hash.slice(1)

    const navbarOptionsToggle = useRef(null)
    const handleLinkClick = () => navbarOptionsToggle.current.click()

    const getNavStyles = (hashName) => {
        if (hash === hashName) {
            return { color: '#FF4548' }
        }
        return {
            color: 'white',
            textDecoration: 'none',
        }
    }

    return (
        <Dropdown>
            <Dropdown.Toggle
                variant="success"
                id="navbar-options-toggle-btn"
                className="ps-0"
                ref={navbarOptionsToggle}
            >
                <label htmlFor="check">
                    <span></span>
                    <span></span>
                    <span></span>
                </label>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Container className="navbar-options-content px-4 py-4 py-md-5">
                    <Row
                        sm={12}
                        className="justify-content-center px-4 pt-3 pb-4"
                    >
                        <Col sm={12} md={6} className="navbar-options-col">
                            <NavLink
                                to="/about-us"
                                onClick={handleLinkClick}
                                style={({ isActive }) => {
                                    if (isActive && hash === '') {
                                        return { color: '#FF4548' }
                                    }
                                    return {
                                        color: 'white',
                                        textDecoration: 'none',
                                    }
                                }}
                            >
                                About Us
                            </NavLink>
                            <br />
                            <NavLink
                                to="/our-services"
                                onClick={handleLinkClick}
                            >
                                Our Services
                            </NavLink>
                            <br />
                            <NavLink
                                to="/about-us#our-team"
                                onClick={handleLinkClick}
                                style={getNavStyles('our-team')}
                            >
                                Our Team
                            </NavLink>
                            <br />
                        </Col>
                        <Col sm={12} md={6} className="navbar-options-col">
                            <NavLink
                                to="/#featured-projects"
                                onClick={handleLinkClick}
                            >
                                Featured Projects
                            </NavLink>
                            <br />
                            <NavLink
                                to="/about-us#schedule-meeting"
                                onClick={handleLinkClick}
                                style={getNavStyles('schedule-meeting')}
                            >
                                Book A Meeting
                            </NavLink>
                            <br />
                            <NavLink to="/contact-us" onClick={handleLinkClick}>
                                Contact Us
                            </NavLink>
                            <br />
                        </Col>
                    </Row>
                </Container>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default NavbarOptions
