import { Container } from 'react-bootstrap'
import React from 'react'
import BannerOverImage from '../common/BannerOverImage'
import Why_reamer from '../../assets/images/home_why.jpg'

const WhyUsSection = () => {
    return (
        <section className="why-us-section bg-white pb-2 px-4 px-md-2 pt-3">
            <Container className="pb-2 pb-md-4 pt-md-3 px-0 px-md-2">
                <BannerOverImage
                    bannerTitle="Why Reamer Made?"
                    bannerContent="We’re a marketing agency made from the ground up for businesses in the dirt world. With numerous years of experience under our treads, RM is a one-of-its-kind shop that understands every aspect of the construction industry from the inside out."
                    buttonText="Know More"
                    buttonLink="/about-us"
                    imageUrl={Why_reamer}
                    altText="3D rendering of a modern house"
                    alignBannerRight={true}
                />
            </Container>
        </section>
    )
}

export default WhyUsSection
