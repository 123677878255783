import Popover from '@mui/material/Popover'
import * as React from 'react'
import { Box } from '@mui/material'

function PopupImage({ src, href, name, service, overview }) {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <>
            <img
                onClick={() => window.open(href, '_blank')}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                src={src}
                alt=""
            />
            <Popover
                id="mouse-over-popover"
                sx={{
                    padding: '40px',
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{ p: 5, width: '500px' }}>
                    <div style={{ marginBottom: '10px' }}>
                        <b>Project Name :</b> {name}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <b>Services Provided :</b> {service}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <b>Overview :</b> {overview}
                    </div>
                    <div>
                        <b>Click to visit site</b>
                    </div>
                </Box>
            </Popover>
        </>
    )
}

export default PopupImage
